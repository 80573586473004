import React, { useState } from "react"
import "../../assets/css/global.css"
import "antd/dist/antd.css"
import { Steps } from "antd"

import styled from "styled-components"
import { Paragraph } from "../Paragraph"

const { Step } = Steps

const ParagraphSmall = styled(Paragraph)`
/*margin: 1em 240px 0.5em 500px;*/
padding: 4em 5% 0 50%;

font-size: 18px;
white-space: normal;
overflow: hidden;
text-overflow: ellipsis;

@media (max-width: 1545px) {
      font-size: 14px;
}
@media (max-width: 1350px) {
      font-size: 14px;
      margin: 1em 140px 0.5em 400px;

}
`
const Firsblock = styled.div`
width: 100%;
display: flex;
position: relative;
flex-direction: column;
justify-content: right;
background-size: cover;
background-position: center;
//min-height: 370px;
/*.bb{
  width: 100%;
  height: 448px;
  position: relative;
  
img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  opacity: 0.5;
}
}*/
`
const FirstblockChild = styled.div`
display: flex;
position: relative;
justify-content: right;
flex-direction: column;
width: 100%;
background-size: cover;
/*height: 500px;*/
.text{
  padding: 4em 5% 0 50%;

  .logo{
    display: flex;
    justify-content: flex-end;
  }
  .subtitle {
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      color: #000000;

    }
  .sub{
    font-style: italic;
    margin-top: 0.5em;

  }
  .paragraph{
    font-size: 18px;
white-space: normal;
overflow: hidden;
text-overflow: ellipsis;
text-align: justify;
  }
}
  .subtitle2{
    position: relative;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 1em;
  }

  .steps {
    position: relative;
    margin-bottom: 2em;
    max-width: 1440px;
    padding-left: 3em;

            .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
              background: #0044B0;
            }
            .ant-steps-item-process .ant-steps-item-icon {
              border-color: #0044B0;
            }
            .ant-steps-item-finish .ant-steps-item-icon {
              border-color: #0044B0;
            }
            .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
              color: #0044B0;
            }
            .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
            color: #0044B0;
            }
            .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
              color: #0044B0;
            }
            .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
              color: #0044B0;
            }

            .ant-steps-item-finish
              > .ant-steps-item-container
              > .ant-steps-item-content
              > .ant-steps-item-title::after {
              background-color: #0044B0;
            }
            .ant-steps-item-title::after {
              height: 3px;
              margin: 0 0 0 160px;
              width: 50px;
            }
            .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
            max-width: 190px;}

  }

  .mobile {
    display: none;
  }

  @media (max-width: 1200px) {
    .text{
      .subtitle {font-size: 22px;}
    .paragraph{font-size: 16px;}
  }}
  @media (max-width: 812px) {

    display: flex;
background-size: 900px ;
height:1050px ;
justify-content:flex-end;

    .text{
    padding: 3em 10% 0 10%;
    .logo{margin-bottom: 1em;}
    .subtitle {font-size: 22px;}
    .sub{margin-bottom: 5px;}
    .paragraph{font-size: 14px;}
  
  }

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      padding: 50px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const ObtainingCertification = ({data,obtainingPhases}) => {
  const paragraphs = data.certificate_getting_content ? data?.certificate_getting_content.split("/") : [];

  const [current, setCurrent] = useState(0)

  const onChange = current => {
    setCurrent(current)
  }

  return (
    <Container>
<Firsblock>
     <FirstblockChild style={{ backgroundImage:`url(${data?.certificate_getting_image})` , backgroundRepeat:'no-repeat' , minHeight: '370px'}}>
            <div className="text">
      <div className="logo"><img src="/images/Certification/AUTODESK.svg"/></div>
      <div className="subtitle">{data?.certificate_getting_title}</div>
      <div className="sub">{data?.certificate_getting_subtitle}</div>
            <div className="paragraph">{paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}</div>
            </div>

            <div className="steps desktop">
              <div className="subtitle2">Pasos para obtener certificación:</div>
                  <Steps current={current} onChange={onChange}>
                    {obtainingPhases?.map(element => (
                      <Step /*title={"Paso " + element.id}*/ description={element?.content}></Step>
                    ))}
                  </Steps>
            </div>

            <div className="steps mobile">
                  <Steps current={current} onChange={onChange} direction="vertical">
                    {obtainingPhases?.map(element => (
                      <Step /*title={"Paso "+element.id}*/ description={element?.content}></Step>
                    ))}
                  </Steps>
            </div>
      </FirstblockChild>
      </Firsblock>
    </Container>
  )
}

export default ObtainingCertification
