import React from "react"
import styled from "styled-components"
import { Button02 } from "../Button02"

import { Title } from "../Title"
import { Paragraph } from "../Paragraph"
import { Items} from "../Items.js"

import "../../assets/css/global.css"

const Titleh1 = styled(Title)`
font-size:34px;
font-weight: 700;
margin: 2em 0 1em 0;
justify-content: center;
padding-left: 5%;
width: 60%;

@media (max-width:900px) {
  width: 100%;
  font-size:23px;
  //margin-left: 1em;
}

@media (max-width:770px) {
  font-size:18px;
  /*margin-left: 2em;*/

}
@media (max-width:500px) {
  font-size:18px;
  /*margin-left: 2em;*/
  padding: 0 2em 0 2em;
  margin: 2em 0 1em 0;
}

`
const Titleh2 = styled(Title)`
font-size:24px;
font-weight: 700;
margin: 2em 0 1em 0;
justify-content: center;
padding-left: 5%;

@media (max-width:900px) {
  width: 70%;
  font-size:18px;
  //margin-left: 2em;
}

/*@media (max-width:770px) {
  width: 70%;
  font-size:18px;
  margin-left: 3em;

}*/

@media (max-width:500px) {
  width: 100%;
  font-size:18px;
  padding:1em 1em 1em 2em;
  margin: 0;
  
}
`

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;

`
const Content = styled.div`
  /*max-width: 1440px;*/
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  justify-self: center;
  flex-direction: column;
  margin-bottom: 2em;
              .info{
                width: 100%;
                min-height: 383.5px;
                display: flex;
                justify-self: center;
                position: relative;
                flex-direction: row;
                margin-bottom: 1em;

                                  .left {
                                    width: 60%;
                                    display: flex;
                                    position: relative;
                                    flex-direction: column;
                                    font-size: 20px;
                                    padding: 10px 5% 0 5%;
                                    line-height: 180%;
                                    font-weight: 500;

                                        .btn{
                                          margin-top: 2em;
                                        }
                                    }

                                  .right {
                                    width: 50%;
                                    position: relative;
                                    display: flex;
                                    /*left: 719px;*/
                                    object-fit: fill;
                                        img {
                                          /*width: 720px;
                                          height: 383.5px;*/
                                          width: 100%;
                                          max-width: 1058px;
                                          object-fit: contain;

                                        }
                                    }
                                  }
              .benefits{
                width: 100%;
                padding-left: 5%;
              }
  @media (max-width: 1250px) {
    .info{
      .left{
        width: 60%;
        font-size: 15px;

      }
    }
  }
  @media (max-width: 900px) {
    .info{
      display: flex;
      flex-direction: column;
      align-items: center;

      .left{
        width: 100%;
        font-size: 15px;
       /* padding-left: 10%;*/

      }
      .right{
        width: 90%;
        position: relative;
        left: 0px;
        display: flex;
        justify-content: center;
        margin-top: 1em;
        /*margin-top: 2em;
        padding-left: 5%;*/
        img {
        }
      }
    }
    .benefits{
      padding-right: 5em;

    }
  }
  @media (max-width: 770px) {
    .info{
      display: flex;
      flex-direction: column;
      align-items: center;

          .left{
            font-size: 14px;
            text-align: justify;
            padding: 0 3em;
            line-height: 150%;
          }
          .right{
            width: 80%;
            position: relative;
            left: 0px;
            margin-top: 2em;
            /*padding-left: 5%;*/
            img {
              
        }
          }
    }
    .benefits{
     //max-width: 425px;
     margin: 0;
     padding: 0 2em;
     /*
      margin-left: 2em;
      padding-right: 7em;
      padding-left: 8%;*/
    }
  }
  
  @media (max-width: 380px) {
    .info{
          .left{
            font-size: 13px;
          }
          .right{
            width: 80%;
            position: relative;
            left: 0px;
            margin-top: 2em;
            /*padding-left: 5%;*/
            img {
          width: 320px;
          padding-left: 1em;
        }
          }
    }
    .benefits{
     margin: 0;
     padding: 0 2em;
    }
  }
`

const Certified = ({generalInfo,data, advantages}) => {

  const paragraphs = data.certificate_content ? data?.certificate_content.split("/") : [];


  return (
    <Container>
      {/*<Titlec>{mainTitle}</Titlec>*/}
      <Content>
      
      <Titleh1>{data?.certificate_title}</Titleh1>
        <div className="info">
                <div className="left">
                <div>{paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}</div>
                      <div className="btn">
                          <a href={generalInfo?.whatsapp_link} target="_blank">
                              <Button02>Conoce más</Button02>
                          </a>
                      </div>
                </div>

                <div className="right">
                      <img src={data?.certificate_image} alt="Brindamos cursos adecuados para especialistas BIM y autocad."/>
                </div>
        </div>

        <Titleh2>{data?.certificate_subtitle}</Titleh2>
        
        <div className="benefits">
                {advantages?.map(element => (
                  <Items key={element?.id} color="#49B3CB">
                    {element?.content}
                  </Items>
                ))}
        </div>

      </Content>
    </Container>
  )
}

export default Certified
