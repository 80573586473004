import React from "react"
import styled from "styled-components"

import { Paragraph } from "../Paragraph"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const Firsblock = styled.div`
width: 100%;
/*min-height: 448px;*/
display: flex;
position: relative;
flex-direction: column;
justify-content: right;
background-size: cover;
background-position: center;
//min-height: 370px;
/*.bb{
  width: 100%;
  height: 448px;
  position: relative;
  
img{
  width: 100%;
  height: 448px;
  object-fit: cover;
  opacity: 0.5;
}
}*/
`
const FirstblockChild = styled.div`
display: flex;
position: relative;
justify-content: right;
flex-direction: column;
width:100%;
background-size: cover;
/*max-height: 450px;*/
padding: 4em 5% 0 50%;

              .title{
                position: relative;
                display: flex;
                font-size: 25px;
                font-weight: 700;
                color: black;
              }
              .text{
                margin-top: 1em;
                position: relative;
                display: flex;
                text-align: justify;
                font-size: 18px;
                overflow: hidden;
                white-space: normal;
              }
              .logo{
                margin: 1em 0 2em 0;
                position: relative;
                display: flex;
              }
              .paragraph{
    font-size: 18px;
white-space: normal;
overflow: hidden;
text-overflow: ellipsis;
text-align: justify;
  }
@media (max-width: 1200px) {
  .title{
    font-size: 22px;
  }
  .text{
    font-size: 16px;
  }
  .paragraph{font-size: 16px;}

}    

@media (max-width: 700px) {
  padding: 3em 10% 0 10%;
display: flex;
background-size: 800px ;
height:650px ;
justify-content:flex-end;


.title{
    font-size: 20px;
  }
  .text{
    font-size: 14px;
  }
  .paragraph{font-size: 14px;}

} 
`


const CertificateQuestions = ({data}) => {
  const paragraphs1 = data.credly_acclaim_content ? data?.credly_acclaim_content.split("/") : [];
  const paragraphs2 = data.digital_badges_content ? data?.digital_badges_content.split("/") : [];

  return (
    <Container>
      <Firsblock>
                                      {/*backgroundImage: '{data.credly_acclaim_image}'*/}
        <FirstblockChild style={{ backgroundImage:`url(${data?.credly_acclaim_image})` , backgroundRepeat:'no-repeat', minHeight: '370px'}}>
        <div className="title">{data?.credly_acclaim_title}</div>
        <div className="paragraph">
        {paragraphs1.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
        </div>
        <div className="logo"><img src="/images/Certification/CERTIPORT.svg"/></div>
        </FirstblockChild>
      </Firsblock>

      <Firsblock>
      {/*<div className="bb"><img src="../images/Certification/Ejem2.png"/></div>*/}
      <FirstblockChild style={{ backgroundImage:`url(${data?.digital_badges_image}` , backgroundRepeat:'no-repeat' , minHeight: '370px'}}>
        <div className="title">{data?.digital_badges_title}</div>
        <div className="paragraph">
        {paragraphs2.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            </div>
        <div className="logo"><img src="/images/Certification/CHAOS.svg"/></div>
        </FirstblockChild>
      </Firsblock>



      {/*<div className="content-text">
        <div className="subtitle">{recieveQuestion}</div>
        <Paragraph>{recieveContent}</Paragraph>
        <div className="subtitle">{preparationQuestion}</div>
        <Paragraph>{preparationContent}</Paragraph>
      </div>
      <div className="content-img">
        <div className="image">
          <img src={preparationImage} alt="Somos una empresa de confianza que entrega certificaicón autodek  y certificación autocad para todos nuestros alumnos" />
        </div>
  </div>*/}
    </Container>
  )
}

export default CertificateQuestions
