import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getCertificationPage, getGeneralInfo } from "../../providers"

import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"
import Certified from "../../components/Certification/Certified"
import ObtainingCertification from "../../components/Certification/ObtainingCertification"
import CertificateQuestions from "../../components/Certification/CertificateQuestions"
import CertificateFeatures from "../../components/Certification/CertificateFeatures"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const Certification = () => {
  const [certificationPage, setCertificationPage] = useState({})
  const [generalInfo, setGeneralInfo] = useState({})

  useEffect(() => {
    const fetchCertification = async () => {
      try {
        const _certificationPage = await getCertificationPage()
        setCertificationPage(_certificationPage[0])

        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])

      } catch (error) {
        console.log(error)
      }
    }
    fetchCertification()
  }, [])

  return (
    <MainLayout>
      <Hero4
        backgroundImage={certificationPage?.background_image}
        title={certificationPage?.title}
      />
      <Container>
        <Certified
        data={certificationPage}
        generalInfo={generalInfo}
        advantages={certificationPage?.advantages}
        />

        <CertificateQuestions
        data={certificationPage}
        />

        <ObtainingCertification
        data={certificationPage}
        obtainingPhases={certificationPage?.certification_steps}
        />

        <CertificateFeatures

          data={certificationPage}
        />
      </Container>
    </MainLayout>
  )
}

export default Certification
