import React from "react"
import styled from "styled-components"

import { Button } from "../Button"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2em;
  `
  const Firsblock = styled.div`
  width: 100%;
  //min-height: 448px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: right;
  background-size: cover;
  background-position: center;
  //min-height: 370px;
  /*.bb{
    width: 100%;
    height: 448px;
    position: relative;
    
  img{
    width: 100%;
    height: 448px;
    object-fit: cover;
    opacity: 0.5;
  }
  }*/
  `
const FirstblockChild = styled.div`
display: flex;
position: relative;
justify-content: right;
flex-direction: column;
width: 100%;
background-size: cover;
padding: 4em 5% 0 50%;

.title{
  position: relative;
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: black;
}
.text{
  margin-top: 1em;
  position: relative;
  display: flex;
  text-align: justify;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  //flex-direction: column;
}
.paragraph{
    font-size: 18px;
white-space: normal;
overflow: hidden;
text-overflow: ellipsis;
text-align: justify;
  }
.logo{
  margin: 1em 0 2em 0;
  position: relative;
  display: flex;
}

@media (max-width: 1200px) {
  .title{
    font-size: 22px;
  }
  .text{
    font-size: 16px;
  }
  .paragraph{font-size: 16px;}

}    
@media (max-width: 700px) {
  padding: 3em 10% 0 10%;
  display: flex;
background-size: 800px ;
height:650px ;
justify-content:flex-end;
  .title{
    font-size: 20px;
  }
  .text{
    font-size: 14px;
  }
  .paragraph{font-size: 14px;}

} 
`

const CertificateFeatures = ({data}) => {
  const paragraphs = data.characteristics_content ? data?.characteristics_content.split("/") : [];

  return (
    <Container>
      <Firsblock>
                                                  {/*data?.*/}
      <FirstblockChild style={{ backgroundImage: `url(${data?.characteristics_image})`, backgroundRepeat:'no-repeat' , minHeight: '370px' }}>
        <div className="title">{data?.characteristics_title}</div>
    
        <div className="paragraph">{paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}</div>
        
        <div className="logo"><img src="/images/Certification/CREDLY.svg"/></div>
      
        </FirstblockChild>
      </Firsblock>
{/*
      <div className="content-img">
        <div className="image">
          <img src={featuresImage} alt="cursos de infraworks y de autodesk para estudiantes. Todos vienen con certificado de autocad." />
        </div>
      </div>
      <div className="btn">
        {/*<a href="https://wa.me/51955943951" target="_blank">
          <Button type="small">Consultar</Button>
          </a>*/}
     
    </Container>
  )
}

export default CertificateFeatures
